export const BASE_URL = "https://backend.mealprep.sultanahmet.ca";

export const REQUEST_TYPES = {
  CREATE: "CREATE",
  UPDATE: "UPDATE",
  DELETE: "DELETE",
};

export const USER_ROLES = {
  MEMBER: "MEMBER",
  ADMIN: "ADMIN",
};
