import React, { useState } from 'react';
import { Menu } from 'antd';
import {
    HiOutlineViewGrid,
    HiUserGroup,
    HiOutlineGift,
    HiOutlineChat,
    HiMenuAlt2,
    HiOutlineRefresh
} from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import Logo from '../images/Logo2.svg';

function CustomMenu ({ setCollapsed, collapsed }) {
    const navigate = useNavigate();
    const [selectedComponent, setSelectedComponent] = useState(); // Default selected component

    const handleMenuClick = (title) => {
        setSelectedComponent(title);
        //navigate(`/${title.toLowerCase().replace(/\s+/g, '-')}`);
        navigate(`/${title.toLowerCase()}`);
    };

    return (
        <Menu

            className=' bg-black font-bold justify-between space-y-2'
            mode="inline"
            selectedKeys={[selectedComponent]}
            style={{ height: '100vh', width: '100%', maxWidth: '300px', borderRight: 0, padding:10, fontFamily: 'Lato', }}
        >
            <Menu.Item key="logo" style={{ justifyContent: 'center', display: 'flex', marginTop: 10, marginBottom:10 }}>
                <div className='flex justify-between'>
                    <div>
                    <img src={Logo} alt="Logo" className='' />
                    </div>
                    <div className="block xl:hidden">
                        <HiMenuAlt2
                            className="cursor-pointer"
                            size={24}
                            color='white'
                            onClick={() => {
                                setCollapsed(!collapsed);
                            }}
                        />
                    </div>
                </div>
            </Menu.Item>
            <Menu.Item key="ORDERS" onClick={() => handleMenuClick('ORDERS')} icon={<HiOutlineViewGrid size={30} />} style={{ backgroundColor: selectedComponent === 'ORDERS' ? '#FF0000' : '#192331', color: 'white', fontSize: '14px' }}>
                ORDERS
            </Menu.Item>
            <Menu.Item key="MEMBERS" onClick={() => handleMenuClick('MEMBERS')} icon={<HiUserGroup size={30} />} style={{ backgroundColor: selectedComponent === 'MEMBERS' ? '#FF0000' : '#192331', color: 'white', fontSize: '14px' }}>
                MEMBERS
            </Menu.Item>
            <Menu.Item key="WEEKLY MENU" onClick={() => handleMenuClick('WEEKLY MENU')} icon={<HiOutlineGift size={30} />} style={{ backgroundColor: selectedComponent === 'WEEKLY MENU' ? '#FF0000' : '#192331', color: 'white', fontSize: '14px' }}>
                WEEKLY MENU
            </Menu.Item>
            <Menu.Item key="MEALS" onClick={() => handleMenuClick('MEALS')} icon={<HiOutlineChat size={30} />} style={{ backgroundColor: selectedComponent === 'MEALS' ? '#FF0000' : '#192331', color: 'white', fontSize: '14px' }}>
                MEALS
            </Menu.Item>
            <Menu.Item key="MEAL TYPES" onClick={() => handleMenuClick('MEAL TYPES')} icon={<HiOutlineRefresh size={30} />} style={{ backgroundColor: selectedComponent === 'MEAL TYPES' ? '#FF0000' : '#192331', color: 'white', fontSize: '14px' }}>
                MEAL TYPES
            </Menu.Item>
        </Menu>
    );
};

export default CustomMenu;