import { useLocation } from "react-router-dom";

function NotFound() {
  const location = useLocation();
  return (
    <div className="h-full w-full flex justify-center items-center" style={{fontFamily:'Lato'}}>
      <p>
        No match for <code>{location.pathname}</code>
      </p>
    </div>
  );
}

export default NotFound;
