import React, { useState } from "react";
import Banner from "../images/bannerLogin.svg";
import Logo from "../images/Logo.svg";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Navigate, useNavigate } from "react-router-dom";
import { Eye as EyeIcon } from "@phosphor-icons/react/dist/ssr/Eye";
import { EyeSlash as EyeSlashIcon } from "@phosphor-icons/react/dist/ssr/EyeSlash";
import { Clock as ClockIcon } from "@phosphor-icons/react/dist/ssr/Clock"; 
import { setCurrentUser, signIn } from "../services/authService";
import { USER_ROLES } from "../constants/settings";
import { Cookies } from "react-cookie";
import { AuthState } from "../context/authContext";

const loginSchema = yup.object({
  email: yup
    .string()
    .email("Email format is not valid")
    .required("Email is required"),
  password: yup.string().required("Password is required"),
});

const Login = () => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: yupResolver(loginSchema),
  });

  const navigate = useNavigate();
  const cookies = new Cookies();
  const { user, setUser } = AuthState();

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const createCookie = (cookieName, value) => {
    cookies.set(cookieName, value, {
      sameSite: "none",
      path: "/",
      expires: new Date(new Date().getTime() + 3 * 24 * 60 * 60 * 1000),
    });
  };

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const res = await signIn(data);

      if (res.data.data.user.role.name === USER_ROLES.ADMIN) {
        createCookie("mealprep-session", res.data.data.token);
        createCookie("mealprep-refresh", res.data.data.refreshToken);
        setCurrentUser(res.data.data.user);
        setUser(res.data.data.user);

        setLoading(false);
        navigate("/orders");
      } else {
        setLoading(false);
        setError("root", {
          message: "Not permitted user",
        });
        throw new Error("Not permitted user");
      }
    } catch (e) {
      console.log(e);
      if (e?.response?.data?.message) {
        setError("root", {
          message: e?.response?.data?.message,
        });
      }
    }
  };

  if (user && user.role.name === USER_ROLES.ADMIN) return <Navigate to={"/orders"} />;

  return (
    <div className="font-lato font-bold min-h-screen flex flex-col md:flex-row">
      <div className="hidden xl:flex flex-1">
        <img src={Banner} alt="banner" className="object-cover w-full h-full" />
      </div>
      <div className="flex flex-1 justify-center items-center mx-auto p-4">
        <form
          className="text-center w-full max-w-md"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          <img src={Logo} alt="Logo" className="hidden xl:block mx-auto" />
          <img src={Logo} alt="Logo" className="xl:hidden mx-auto mt-20" />
          <p className="text-start font-bold text-2xl mb-1 mt-12 xl:mt-0 xl:block hidden">
            Welcome Back.
          </p>
          <p className="text-start text-xs mb-10 xl:mb-0 xl:block hidden">
            Login with your credentials
          </p>

          <p className="mx-10 text-start font-bold text-2xl mb-1 mt-12 xl:hidden">
            Welcome Back.
          </p>
          <p className="mx-10 text-start text-xs mb-10 xl:hidden">
            Login with your credentials
          </p>

          <input
            className="border border-gray-300 rounded-lg text-xs w-full h-10 px-4 mb-3 xl:mt-3"
            placeholder="Email Address"
            type="email"
            {...register("email")}
          />
          {errors.email && (
            <div className="text-left text-xs text-red-500 my-2">
              {errors.email.message}
            </div>
          )}
          <div className="relative mb-3">
            <input
              className="border border-gray-300 rounded-lg text-xs w-full h-10 px-4 pr-10"
              placeholder="Password"
              type={showPassword ? "text" : "password"}
              {...register("password")}
            />
            <button
              type="button"
              className="absolute inset-y-0 right-0 pr-3 flex items-center"
              onClick={togglePasswordVisibility}
            >
              {showPassword ? (
                <EyeIcon
                  cursor="pointer"
                  fontSize="var(--icon-fontSize-md)"
                />
              ) : (
                <EyeSlashIcon
                  cursor="pointer"
                  fontSize="var(--icon-fontSize-md)"
                />
              )}
            </button>
          </div>
          {errors.password && (
            <div className="text-left text-xs text-red-500 my-2">
              {errors.password.message}
            </div>
          )}

          {errors.root && (
            <div className="text-left text-xs text-red-500 my-2">
              {errors.root.message}
            </div>
          )}
          <button className="bg-black w-full h-9 text-white font-semibold rounded-lg text-sm mt-6">
            {loading ? (
              <div className="flex items-center justify-center">
                <ClockIcon className="animate-spin mr-2" />
                Logging In...
              </div>
            ) : (
              "Login"
            )}
          </button>
          <p className="mx-auto mt-10 mb-1 text-xs">
            © 2024 SA-MealPrep. All rights reserved.
          </p>
        </form>
      </div>
    </div>
  );
};

export default Login;
