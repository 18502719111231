import { BASE_URL } from "../constants/settings";
import { axiosHttp } from "../intercepters/axiosHandler";

export const getUsers = async ({ page, limit }) => {
  try {
    const response = await axiosHttp.get(`${BASE_URL}/api/v1/users?page=${page}&limit=${limit}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching list of the users:", error);
    throw error;
  }
};


// Get User by ID
export const getUserById = async (userId) => {
  try {
    const response = await axiosHttp.get(`${BASE_URL}/api/v1/users/${userId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching user by ID:", error);
    throw error;
  }
};

// Delete User
export const deleteUser = async (userId) => {
  try {
    const response = await axiosHttp.delete(`${BASE_URL}/api/v1/users/${userId}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting user:", error);
    throw error;
  }
};

//Update
export const updateUser = async (userId, userData) => {
  try {
    const response = await axiosHttp.put(`${BASE_URL}/api/v1/users/${userId}`, userData);
    return response.data;
  } catch (error) {
    console.error("Error updating user:", error);
    throw error;
  }
};