import React from 'react';
import OrderDetailMeter from '../components/dashboard/Orders/OrderDetailMeter';
import BasicTable from '../components/dashboard/Orders/InfoTable';

// Orders component with responsive design
const Orders = () => {
    return (
        <div className='space-y-3 sm:space-y-7 bg-center' style={{fontFamily:'Lato'}}>
            <div className='grid grid-cols-1 md:grid-cols-2 items-center p-4 md:px-12'>
                <div className='text-start text-xl md:text-3xl font-bold'>Orders</div>
                <OrderDetailMeter />
            </div>
            <div className='px-4 md:px-12'>
                <BasicTable />
            </div>
        </div>
    );
}

export default Orders;