import axios from 'axios';
import { BASE_URL } from "../constants/settings";
import { axiosHttp } from "../intercepters/axiosHandler";



export const getMealById = async (mealId) => {
    if (!mealId) throw new Error("Meal ID is required");
    
    try {
      const response = await axiosHttp.get(`${BASE_URL}/api/v1/meals/${mealId}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching meal by ID:", error);
      throw error;
    }
  };
  
  export const updateMeal = async (mealId, mealData) => {
    if (!mealId) throw new Error("Meal ID is required");
  
    try {
      const response = await axiosHttp.patch(`${BASE_URL}/api/v1/meals/${mealId}`, mealData);
      return response.data;
    } catch (error) {
      console.error("Error updating meal:", error);
      throw error;
    }
  };

export const uploadMealImage = async (mealId, formData) => {
  if (!mealId) {
    throw new Error("Meal ID is required");
  }

  try {
    const response = await axios.post(`${BASE_URL}/api/v1/meals/${mealId}/images`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    // Ensure the response structure is as expected
    if (response.status === 200 && response.data && response.data.url) {
      return response.data; // Adjust this based on your API response structure
    } else {
      throw new Error("Unexpected response format");
    }
  } catch (error) {
    console.error("Error uploading meal image:", error);
    throw error;
  }
};