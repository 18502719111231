// import { BASE_URL } from "../constants/settings";
// import { axiosHttp } from "../intercepters/axiosHandler";

// //Get Category List (GET /api/v1/categories):
// export const getCategoryList = async () => {
//     try {
//         const response = await axiosHttp.get(`${BASE_URL}/api/v1/categories`);
//         return response.data;
//     } catch (error) {
//         console.error("Error fetching category list:", error);
//         throw error;
//     }
// };

// //get cat by id
// export const getCategoryById = async (categoryId) => {
//     try {
//         const response = await axiosHttp.get(`${BASE_URL}/categories/${categoryId}`);
//         return response.data;
//     } catch (error) {
//         console.error("Error fetching category by ID:", error);
//         throw error;
//     }
// };


// //Create Category (POST /api/v1/categories):
// export const createCategory = async (categoryData) => {
//     try {
//         const response = await axiosHttp.post(`${BASE_URL}/api/v1/categories`, categoryData);
//         return response.data;
//     } catch (error) {
//         console.error("Error creating category:", error);
//         throw error;
//     }
// };

// //Update Category Data (PATCH /api/v1/categories/{id}):
// export const updateCategory = async (categoryId, categoryData) => {
//     try {
//         const response = await axiosHttp.patch(`${BASE_URL}/api/v1/categories/${categoryId}`, categoryData);
//         return response.data;
//     } catch (error) {
//         console.error("Error updating category:", error.response ? error.response.data : error.message);
//         throw error;
//     }
// };


// //Delete Category by ID (DELETE /api/v1/categories/{id}):
// export const deleteCategory = async (categoryId) => {
//     try {
//         const response = await axiosHttp.delete(`${BASE_URL}/api/v1/categories/${categoryId}`);
//         return response.data;
//     } catch (error) {
//         console.error("Error deleting category:", error);
//         throw error;
//     }
// };






import { BASE_URL } from "../constants/settings";
import { axiosHttp } from "../intercepters/axiosHandler";

// Get Category List (GET /api/v1/categories):
export const getCategoryList = async () => {
    try {
        const response = await axiosHttp.get(`${BASE_URL}/api/v1/categories`);
        return response.data;
    } catch (error) {
        console.error("Error fetching category list:", error);
        throw error;
    }
};

// Get Category by ID (GET /api/v1/categories/{id}):
export const getCategoryById = async (categoryId) => {
    try {
        const response = await axiosHttp.get(`${BASE_URL}/api/v1/categories/${categoryId}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching category by ID:", error);
        throw error;
    }
};

// Create Category (POST /api/v1/categories):
export const createCategory = async (categoryData) => {
    try {
        const response = await axiosHttp.post(`${BASE_URL}/api/v1/categories`, categoryData);
        return response.data;
    } catch (error) {
        console.error("Error creating category:", error);
        throw error;
    }
};

// Update Category Data (PATCH /api/v1/categories/{id}):
export const updateCategory = async (categoryId, categoryData) => {
    try {
      // Ensure categoryData is formatted correctly
      const response = await axiosHttp.patch(`${BASE_URL}/api/v1/categories/${categoryId}`, categoryData, {
        headers: {
          "Content-Type": "application/json", // Make sure the request is JSON
        },
      });
  
      // Check if the response is successful
      if (response.status === 200) {
        return response.data; // Return the updated data
      } else {
        throw new Error(`Failed with status code: ${response.status}`);
      }
    } catch (error) {
      console.error("Error updating category:", error.response ? error.response.data : error.message);
      throw error;
    }
  };
  
  

// Delete Category by ID (DELETE /api/v1/categories/{id}):
export const deleteCategory = async (categoryId) => {
    try {
        const response = await axiosHttp.delete(`${BASE_URL}/api/v1/categories/${categoryId}`);
        return response.data;
    } catch (error) {
        console.error("Error deleting category:", error);
        throw error;
    }
};