import { BASE_URL } from "../constants/settings";
import { axiosHttp } from "../intercepters/axiosHandler";

// Get order list
export const getOrderList = async (pagination) => {
    try {
        const {page, limit} = pagination;
        const response = await axiosHttp.get(`${BASE_URL}/api/v1/orders?page=${page}&limit=${limit}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching order list:", error);
        throw error;
    }
};

// Create order
export const createOrder = async (orderData) => {
    try {
        const response = await axiosHttp.post(`${BASE_URL}/api/v1/orders`, orderData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

// Get order by ID
export const getOrderById = async (orderId) => {
    try {
        const response = await axiosHttp.get(`${BASE_URL}/api/v1/orders/${orderId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

// Update order data
export const updateOrder = async (orderId, updatedData) => {
    try {
        const response = await axiosHttp.patch(`${BASE_URL}/api/v1/orders/${orderId}`, updatedData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

// Get checkout session by ID
export const getCheckoutSessionById = async (orderId, checkoutSessionId) => {
    try {
        const response = await axiosHttp.get(`${BASE_URL}/api/v1/orders/${orderId}/checkout-session/${checkoutSessionId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

// Update order status to SHIPPED
export const updateOrderToShipped = async (orderId) => {
    try {
        const response = await axiosHttp.patch(`${BASE_URL}/api/v1/orders/${orderId}`, { status: 'SHIPPED' });
        return response.data;
    } catch (error) {
        throw error;
    }
};

// Update order status to INTRANSIT
export const updateOrderToInTransit = async (orderId) => {
    try {
        const response = await axiosHttp.patch(`${BASE_URL}/api/v1/orders/${orderId}`, { status: 'INTRANSIT' });
        return response.data;
    } catch (error) {
        throw error;
    }
};

// Update order status to DELIVERED
export const updateOrderToDelivered = async (orderId) => {
    try {
        const response = await axiosHttp.patch(`${BASE_URL}/api/v1/orders/${orderId}`, { status: 'DELIVERED' });
        return response.data;
    } catch (error) {
        throw error;
    }
};

// Update order status to PENDING
export const updateOrderToPending = async (orderId) => {
    try {
        const response = await axiosHttp.patch(`${BASE_URL}/api/v1/orders/${orderId}`, { status: 'PENDING' });
        return response.data;
    } catch (error) {
        throw error;
    }
};


// Update order status to PAID
export const updateOrderToPaid = async (orderId) => {
    try {
        const response = await axiosHttp.patch(`${BASE_URL}/api/v1/orders/${orderId}`, { status: 'PAID' });
        return response.data;
    } catch (error) {
        throw error;
    }
};