import React from 'react';
import { Link } from 'react-router-dom';
import MealsTable from '../components/dashboard/Meals/Meals';
import { PlusOutlined } from '@ant-design/icons';

const Meals = () => {
    return (
        
        <div className='px-4 sm:px-12 my-4'style={{fontFamily:'Lato'}}>
            <div className='flex justify-between items-center flex-wrap'>
                <h1 className="text-xl sm:text-3xl font-bold m-5">Meals</h1>
                <Link to="/create-meal" className='bg-black text-white py-2 px-4 rounded-md'>
                    <PlusOutlined /> Add Meal
                </Link>
            </div>
            <MealsTable />
        </div>
    );
    
}

export default Meals;