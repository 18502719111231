// import React, { useEffect, useState } from 'react';
// import { getOrderList } from '../../../services/orderService';
// import { Stat, StatNumber, StatHelpText, StatGroup } from '@chakra-ui/react';

// const OrderDetailMeter = () => {
//     const [orderCounts, setOrderCounts] = useState({
//         PLACED: 0,
//         PAID: 0,
//         // PENDING: 0,
//         SHIPPED: 0,
//         INTRANSIT: 0,
//         DELIVERED: 0,
//     });

//     useEffect(() => {
//         fetchOrderCounts();
//     }, []);

//     const fetchOrderCounts = async () => {
//         try {
//             // Assuming getOrderList() retrieves all orders without pagination
//             const response = await getOrderList({ page: 1, limit: 1000 });  // Adjust the limit as needed based on your backend support
//             const orders = response.data.list || [];
//             const counts = orders.reduce((acc, order) => {
//                 const { status } = order;
//                 if (status in acc) {
//                     acc[status] += 1;
//                 }
//                 return acc;
//             }, {
//                 PLACED: 0,
//                 PAID: 0,
//                 // PENDING: 0,
//                 SHIPPED: 0,
//                 INTRANSIT: 0,
//                 DELIVERED: 0,
//             });

//             setOrderCounts(counts);
//         } catch (error) {
//             console.error('Error fetching order counts:', error);
//         }
//     };

//     return (
        
//         <div className='overflow-scroll' style={{fontFamily:'Lato'}}>
//         <div className="bg-white rounded-lg font-semibold overflow-hidden">
//             <StatGroup className="p-2 mx-2 sm:mx-5 space-x-2 sm:space-x-4 text-center" style={{fontFamily:'Lato'}}>
//                 {Object.entries(orderCounts).map(([status, count]) => (
//                     <Stat key={status}>
//                         <StatNumber>{count}</StatNumber>
//                         <StatHelpText>
//                             <p className="text-xs">{status}</p>
//                         </StatHelpText>
//                     </Stat>
//                 ))}
//             </StatGroup>
//         </div>
//         </div>
//     );
// };

// export default OrderDetailMeter;

import React, { useEffect, useState } from 'react';
import { getOrderList } from '../../../services/orderService';
import { Stat, StatNumber, StatHelpText, StatGroup } from '@chakra-ui/react';
import './OrderDetailMeter.css';

const OrderDetailMeter = () => {
    const [orderCounts, setOrderCounts] = useState({
        PLACED: 0,
        PAID: 0,
        // PENDING: 0,
        SHIPPED: 0,
        INTRANSIT: 0,
        DELIVERED: 0,
    });

    useEffect(() => {
        fetchOrderCounts();
    }, []);

    const fetchOrderCounts = async () => {
        try {
            // Assuming getOrderList() retrieves all orders without pagination
            const response = await getOrderList({ page: 1, limit: 1000 });  // Adjust the limit as needed based on your backend support
            const orders = response.data.list || [];
            const counts = orders.reduce((acc, order) => {
                const { status } = order;
                if (status in acc) {
                    acc[status] += 1;
                }
                return acc;
            }, {
                PLACED: 0,
                PAID: 0,
                // PENDING: 0,
                SHIPPED: 0,
                INTRANSIT: 0,
                DELIVERED: 0,
            });

            setOrderCounts(counts);
        } catch (error) {
            console.error('Error fetching order counts:', error);
        }
    };

    return (
        <div className='order-detail-meter-container'>
            <div className="order-detail-meter">
                <StatGroup className="stat-group">
                    {Object.entries(orderCounts).map(([status, count]) => (
                        <Stat key={status} className="stat-item">
                            <StatNumber>{count}</StatNumber>
                            <StatHelpText>
                                <p className="status-text">{status}</p>
                            </StatHelpText>
                        </Stat>
                    ))}
                </StatGroup>
            </div>
        </div>
    );
};

export default OrderDetailMeter;