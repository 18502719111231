import React, { useState, useEffect } from 'react';
import { Button, Form, Input, InputNumber, message, Select, Upload } from 'antd';
import { axiosHttp } from '../../../intercepters/axiosHandler';
import { BASE_URL } from '../../../constants/settings';
import { uploadMealImage } from '../../../services/mealService';
import { getCategoryList } from '../../../services/categoryService';

const { Option } = Select;
const { TextArea } = Input;

const CreateMeal = () => {
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    price: 0,
    portionSize: 0,
    ingredients: '',
    contains: '',
    carbohydrate: 0,
    protein: 0,
    fat: 0,
    calories: 0,
    foodAllergyNotice: '',
    storageInstructions: '',
    heatingFromFreshInstructions: '',
    heatingFromFrozenInstructions: '',
    categoryIds: [],
    mealNutritions: []
  });
  const [categories, setCategories] = useState([]);
  const [imageFile, setImageFile] = useState(null);
  const [createForm] = Form.useForm();

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await getCategoryList();
      setCategories(response.data.list || []);
    } catch (error) {
      console.error("Error fetching category list:", error);
      message.error('Failed to fetch categories. Please check the console for more details.');
      setCategories([]);
    }
  };

  const handleChange = (name, value) => {
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleImageChange = (info) => {
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
      setImageFile(info.file.originFileObj);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const handleSubmit = async (values) => {
    try {
      let imageUrl = '';
      if (imageFile) {
        const imageData = new FormData();
        imageData.append('mealFile', imageFile);
        const imageResponse = await uploadMealImage(imageData);
        imageUrl = imageResponse.url;
      }

      const mealData = { ...values, imageUrl };
      const response = await axiosHttp.post(`${BASE_URL}/api/v1/meals`, mealData);
      message.success('Meal created successfully!');
      console.log("Meal Creation Response:", response.data);
    } catch (error) {
      console.error('Error creating meal:', error);
      message.error('Failed to create meal. Please check the console for more details.');
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-5" style={{ fontFamily: 'Lato' }}>
      <h1 className="text-lg font-bold mb-4">Create New Meal</h1>
      <Form form={createForm} layout="vertical" onFinish={handleSubmit}>
        <Form.Item name="name" label="Meal Name" rules={[{ required: true, message: "Please input meal name!" }]}>
          <Input placeholder='Meal Name' onChange={e => handleChange('name', e.target.value)} />
        </Form.Item>
        <Form.Item name="description" label="Description" rules={[{ required: true, message: "Please input meal description!" }]}>
          <TextArea rows={4} placeholder='Meal Description' onChange={e => handleChange('description', e.target.value)} />
        </Form.Item>
        <Form.Item name="price" label="Price" rules={[{ required: true, message: "Please input meal price!" }]}>
          <InputNumber min={0} onChange={value => handleChange('price', value)} />
        </Form.Item>
        <Form.Item name="portionSize" label="Portion Size" rules={[{ required: true, message: "Please input portion size!" }]}>
          <InputNumber min={0} onChange={value => handleChange('portionSize', value)} />
        </Form.Item>
        <Form.Item name="ingredients" label="Ingredients" rules={[{ required: true, message: "Please input ingredients!" }]}>
          <TextArea rows={4} onChange={e => handleChange('ingredients', e.target.value)} />
        </Form.Item>
        <Form.Item name="contains" label="Contains" rules={[{ required: true, message: "Please input contains!" }]}>
          <Input onChange={e => handleChange('contains', e.target.value)} />
        </Form.Item>
        <Form.Item name="carbohydrate" label="Carbohydrate (g)" rules={[{ required: true, message: "Please input carbohydrate amount!" }]}>
          <InputNumber min={0} onChange={value => handleChange('carbohydrate', value)} />
        </Form.Item>
        <Form.Item name="protein" label="Protein (g)" rules={[{ required: true, message: "Please input protein amount!" }]}>
          <InputNumber min={0} onChange={value => handleChange('protein', value)} />
        </Form.Item>
        <Form.Item name="fat" label="Fat (g)" rules={[{ required: true, message: "Please input fat amount!" }]}>
          <InputNumber min={0} onChange={value => handleChange('fat', value)} />
        </Form.Item>
        <Form.Item name="calories" label="Calories (kcal)" rules={[{ required: true, message: "Please input calories amount!" }]}>
          <InputNumber min={0} onChange={value => handleChange('calories', value)} />
        </Form.Item>
        <Form.Item name="foodAllergyNotice" label="Food Allergy Notice" rules={[{ required: true, message: "Please input food allergy notice!" }]}>
          <TextArea rows={4} onChange={e => handleChange('foodAllergyNotice', e.target.value)} />
        </Form.Item>
        <Form.Item name="storageInstructions" label="Storage Instructions" rules={[{ required: true, message: "Please input storage instructions!" }]}>
          <TextArea rows={4} onChange={e => handleChange('storageInstructions', e.target.value)} />
        </Form.Item>
        <Form.Item name="heatingFromFreshInstructions" label="Heating from Fresh Instructions" rules={[{ required: true, message: "Please input heating from fresh instructions!" }]}>
          <TextArea rows={4} onChange={e => handleChange('heatingFromFreshInstructions', e.target.value)} />
        </Form.Item>
        <Form.Item name="heatingFromFrozenInstructions" label="Heating from Frozen Instructions" rules={[{ required: true, message: "Please input heating from frozen instructions!" }]}>
          <TextArea rows={4} onChange={e => handleChange('heatingFromFrozenInstructions', e.target.value)} />
        </Form.Item>
        <Form.Item name="categoryIds" label="Category" rules={[{ required: true, message: "Please select categories!" }]}>
          <Select
            mode="multiple"
            placeholder="Select categories"
            onChange={value => handleChange('categoryIds', value)}
          >
            {categories.map(category => (
              <Option key={category.id} value={category.id}>
                {category.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {/* <Form.Item label="Meal Image">
          <Upload
            name="mealFile"
            listType="picture"
            beforeUpload={() => false}
            onChange={handleImageChange}
          >
            <Button icon={<PlusOutlined />}>Upload Image</Button>
          </Upload> */}
          {/* {formData.imageUrl && (
            <img src={formData.imageUrl} alt="Meal" style={{ width: '100px', marginTop: '10px' }} />
          )} */}
        {/* </Form.Item> */}
        <Form.Item>
          <Button type="primary" htmlType="submit" className='bg-black hover:bg-blue-700 text-white font-bold rounded'>Create Meal</Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CreateMeal;