import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Input,
  InputNumber,
  message,
  Select,
  Upload,
} from "antd";
import { updateMeal, uploadMealImage } from "../../../services/mealService";
import { getCategoryList } from "../../../services/categoryService";
import { PlusOutlined } from "@ant-design/icons";

const { Option } = Select;
const { TextArea } = Input;

const MealEditForm = ({ meal }) => {
  const [formData, setFormData] = useState(meal);
  const [categories, setCategories] = useState([]);
  const [imgForm] = Form.useForm();

  const setFormValues = () => {
    const categoryIds = [];
    for (let c of meal.categories) {
      categoryIds.push(c.id);
    }
    imgForm.setFieldsValue({
      name: meal.name,
      description: meal.description,
      price: meal.price,
      posionSize: meal.posionSize,
      ingredients: meal.ingredients,
      contains: meal.contains,
      carbohydrate: meal.carbohydrate,
      protein: meal.protein,
      fat: meal.fat,
      calories: meal.calories,
      foodAllergyNotice: meal.foodAllergyNotice,
      storageInstructions: meal.storageInstructions,
      heatingFromFreshInstructions: meal.heatingFromFreshInstructions,
      heatingFromFrozenInstructions: meal.heatingFromFrozenInstructions,
      categoryIds: categoryIds,
    });
  };

  useEffect(() => {
    if (meal) {
      setFormValues();
    }
  }, [meal]);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await getCategoryList();
      setCategories(response.data.list || []);
    } catch (error) {
      console.error("Error fetching category list:", error);
      message.error(
        "Failed to fetch categories. Please check the console for more details."
      );
      setCategories([]);
    }
  };

  const handleChange = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };


  const handleSubmit = async (values) => {
    try {

      if (values.mealFile) {
        const imageData = new FormData();
        imageData.append('id', meal.id);
        imageData.append("mealFile", values.mealFile[0].originFileObj);
        const imageResponse = await uploadMealImage(meal.id, imageData);
        console.log(imageResponse);
        // console.log(imageData);
      }

      const mealData = { ...values, id: meal.id };
      const response = await updateMeal(meal.id, mealData);
      message.success('Meal updated successfully!');
    } catch (error) {
      console.error("Failed to update meal:", error);
      message.error("Failed to update meal.");
    }
  };

  return (
    <Form form={imgForm} layout="vertical" onFinish={handleSubmit}>
      <Form.Item
        name="name"
        label="Meal Name"
        rules={[{ required: true, message: "Please input meal name!" }]}
      >
        <Input placeholder="Meal Name" />
      </Form.Item>
      <Form.Item
        name="description"
        label="Description"
        rules={[{ required: true, message: "Please input meal description!" }]}
      >
        <TextArea rows={4} placeholder="Meal Description" />
      </Form.Item>
      <Form.Item
        name="price"
        label="Price"
        rules={[{ required: true, message: "Please input meal price!" }]}
      >
        <InputNumber min={0} />
      </Form.Item>
      <Form.Item
        name="posionSize"
        label="Portion Size"
        rules={[{ required: true, message: "Please input portion size!" }]}
      >
        <InputNumber min={0} />
      </Form.Item>
      <Form.Item
        name="ingredients"
        label="Ingredients"
        rules={[{ required: true, message: "Please input ingredients!" }]}
      >
        <TextArea rows={4} />
      </Form.Item>
      <Form.Item
        name="contains"
        label="Contains"
        rules={[{ required: true, message: "Please input contains!" }]}
      >
        <Input placeholder="contains" />
      </Form.Item>
      <Form.Item
        name="carbohydrate"
        label="Carbohydrate (g)"
        rules={[
          { required: true, message: "Please input carbohydrate amount!" },
        ]}
      >
        <InputNumber
          min={0}
          
        />
      </Form.Item>
      <Form.Item
        name="protein"
        label="Protein (g)"
        rules={[{ required: true, message: "Please input protein amount!" }]}
      >
        <InputNumber
          min={0}
          
        />
      </Form.Item>
      <Form.Item
        name="fat"
        label="Fat (g)"
        rules={[{ required: true, message: "Please input fat amount!" }]}
      >
        <InputNumber min={0} onChange={(value) => handleChange("fat", value)} />
      </Form.Item>
      <Form.Item
        name="calories"
        label="Calories (kcal)"
        rules={[{ required: true, message: "Please input calories amount!" }]}
      >
        <InputNumber
          min={0}
          
        />
      </Form.Item>
      <Form.Item
        name="foodAllergyNotice"
        label="Food Allergy Notice"
        rules={[
          { required: true, message: "Please input food allergy notice!" },
        ]}
      >
        <TextArea
          rows={4}
          
        />
      </Form.Item>
      <Form.Item
        name="storageInstructions"
        label="Storage Instructions"
        rules={[
          { required: true, message: "Please input storage instructions!" },
        ]}
      >
        <TextArea
          rows={4}
          
        />
      </Form.Item>
      <Form.Item
        name="heatingFromFreshInstructions"
        label="Heating from Fresh Instructions"
        rules={[
          {
            required: true,
            message: "Please input heating from fresh instructions!",
          },
        ]}
      >
        <TextArea
          rows={4}
          
        />
      </Form.Item>
      <Form.Item
        name="heatingFromFrozenInstructions"
        label="Heating from Frozen Instructions"
        rules={[
          {
            required: true,
            message: "Please input heating from frozen instructions!",
          },
        ]}
      >
        <TextArea
          rows={4}
          
        />
      </Form.Item>
      <Form.Item
        name="categoryIds"
        label="Category"
        rules={[{ required: true, message: "Please select categories!" }]}
      >
        <Select
          mode="multiple"
          placeholder="Select categories"
          // onChange={value => handleChange('categoryIds', value)}
        >
          {categories.map((category) => (
            <Option key={category.id} value={category.id}>
              {category.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name={"mealFile"}
        valuePropName="fileList"
        getValueFromEvent={(e) => {
          if (Array.isArray(e)) {
            return e;
          }
          return e?.fileList
        }}
        label="Meal Image"
      >
        <Upload listType="picture" maxCount={1} beforeUpload={() => false}>
          <Button icon={<PlusOutlined />}>Upload Image</Button>
        </Upload>
      </Form.Item>
      {meal.imageUrl && (
          <img
            className="rounded-xl"
            src={meal.imageUrl}
            alt="Meal"
            style={{ width: "300px", marginTop: "10px" }}
          />
        )}
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="mt-5 bg-black hover:bg-blue-700 text-white font-bold rounded"
        >
          Update Meal
        </Button>
      </Form.Item>
    </Form>
  );
};

export default MealEditForm;