// import React, { useEffect, useState, useCallback } from 'react';
// import { Table, message } from 'antd';
// import { getUsers } from '../../../services/userService';

// const MemberTable = () => {
//   const [loading, setLoading] = useState(false);
//   const [users, setUsers] = useState([]);
//   const [current, setCurrent] = useState(1);
//   const [pageSize, setPageSize] = useState(10); // Set to 10 for better mobile responsiveness
//   const [total, setTotal] = useState(0);

//   const fetchUsers = useCallback(async (page, pageSize) => {
//     setLoading(true);
//     try {
//       const response = await getUsers({ page, limit: pageSize });
//       setUsers(response.data.list || []);
//       setTotal(response.data.count); // Assume API returns total count
//     } catch (error) {
//       console.error('Error fetching users:', error);
//       message.error('Failed to fetch users data');
//     } finally {
//       setLoading(false);
//     }
//   }, []);

//   useEffect(() => {
//     fetchUsers(current, pageSize);
//   }, [fetchUsers, current, pageSize]);

//   const handleTableChange = (newPagination) => {
//     setCurrent(newPagination.current);
//     setPageSize(newPagination.pageSize);
//   };

//   const columns = [
//     // {
//     //   title: 'Member ID',
//     //   dataIndex: 'id',
//     //   key: 'id',
//     // },
//     {
//       title: 'Name',
//       dataIndex: 'name',
//       key: 'name',
//     },
//     {
//       title: 'Email',
//       dataIndex: 'email',
//       key: 'email',
//       //responsive: ['md'],
//     },
//     {
//       title: 'Phone',
//       dataIndex: 'mobileNumber',
//       key: 'mobileNumber',
//       //responsive: ['md'],
//     },
//   ];

//   return (
//     <div className='overflow-x-auto'>
//       <Table
//         style={{ fontFamily: 'Lato', textAlign: 'center' }}
//         columns={columns}
//         dataSource={users}
//         rowKey="id"
//         loading={loading}
//         pagination={{
//           current,
//           pageSize,
//           total,
//         }}
//         onChange={handleTableChange}
//         scroll={{ x: 800 }} // Ensures horizontal scroll on small screens
//       />
//     </div>
//   );
// };

// export default MemberTable;

import React, { useEffect, useState } from 'react';
import { Space, Table, Button, Popconfirm, message } from 'antd';
import { getCategoryList, deleteCategory } from '../../../services/categoryService';
import { HiPencilAlt, HiOutlineTrash } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';

const MealTypesTable = () => {
    const [loading, setLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        fetchCategories();
    }, []);

    const fetchCategories = async () => {
        setLoading(true);
        try {
            const response = await getCategoryList();
            const { data } = response;
            setCategories(data.list);
        } catch (error) {
            console.error('Error fetching categories:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleEdit = (categoryId) => {
        navigate(`/editCategory/${categoryId}`);
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Button icon={<HiPencilAlt size={20} />} onClick={() => handleEdit(record.id)} className="p-1" />
                    <Popconfirm 
                        title="Are you sure to delete this category?" 
                        onConfirm={() => handleDelete(record.id)} 
                        okText="Yes" 
                        cancelText="No" 
                        okButtonProps={{ danger: true }}
                    >
                        <Button icon={<HiOutlineTrash size={20} />} danger className="p-1" />
                    </Popconfirm>
                </Space>
            ),
        }
    ];

    const handleDelete = async (categoryId) => {
        try {
            await deleteCategory(categoryId);
            setCategories(categories.filter(category => category.id !== categoryId));
            message.success('Category deleted successfully');
        } catch (error) {
            console.error('Error deleting category:', error);
            message.error('Failed to delete category. Please try again.');
        }
    };

    return (
        <div className='overflow-x-auto' style={{ fontFamily: 'Lato' }}>
            <Table
                style={{ fontFamily: 'Lato' }}
                dataSource={categories}
                columns={columns}
                rowKey="id"
                loading={loading}
                pagination={{ pageSize: 10 }}
                scroll={{ x: 800 }} // Ensures horizontal scroll on small screens
            />
        </div>
    );
}

export default MealTypesTable;
