import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Select, message } from 'antd';
import { getUserById, updateUser } from '../../../services/userService';

const { Option } = Select;

const UserEditForm = ({ userId, onUpdate }) => {
  const [form] = Form.useForm();
  const [userData, setUserData] = useState({});

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await getUserById(userId);
        const { data } = response;
        setUserData(data);
        form.setFieldsValue(data);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, [form, userId]);

  const onFinish = async (values) => {
    try {
      await updateUser(userId, values);
      onUpdate(values);
    } catch (error) {
      console.error('Error updating user:', error);
      message.error('Update faild : error !');
    }
  };

  return (
    <div className='overflow-x-auto' style={{fontFamily:'Lato'}}>
      <Form form={form} onFinish={onFinish} initialValues={userData} labelCol={{ span: 4 }} className='grid grid-cols-1 gap-4' style={{fontFamily:'Lato'}}>
        <div className='xl:hidden mx-auto '>
        <Form.Item label="First Name" name="firstName">
          <Input />
        </Form.Item>
        <Form.Item label="Last Name" name="lastName">
          <Input />
        </Form.Item>
        <Form.Item label="Email" name="email">
          <Input />
        </Form.Item>
        <Form.Item label="Role" name="role">
          <Select>
            <Option value="ADMIN">Admin</Option>
            <Option value="USER">User</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Phone" name="mobileNumber">
        <Input />
        </Form.Item>
      
      <Form.Item>
        <Button type="primary" htmlType="submit" className=' bg-black'>
          Update
        </Button>
      </Form.Item>
        </div>
    </Form>

    <Form form={form} onFinish={onFinish} initialValues={userData} labelCol={{ span: 4 }} className='grid grid-cols-1 gap-4'>
        <div className='hidden xl:block px-40 '>
        <Form.Item label="First Name" name="firstName">
          <Input />
        </Form.Item>
        <Form.Item label="Last Name" name="lastName">
          <Input />
        </Form.Item>
        <Form.Item label="Email" name="email">
          <Input />
        </Form.Item>
        <Form.Item label="Role" name="role">
          <Select>
            <Option value="ADMIN">Admin</Option>
            <Option value="USER">User</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Phone" name="mobileNumber">
        <Input />
        </Form.Item>
      
      <Form.Item>
        <Button type="primary" htmlType="submit" className=' bg-black'>
          Update
        </Button>
      </Form.Item>
        </div>
    </Form>
    </div>
  );
};

export default UserEditForm;