import React, { useState } from 'react';
import Header from "./header";
import Menu from "./menu";
import NavProvider from "../context/navContext";
import { AuthState } from "../context/authContext";
import { USER_ROLES } from "../constants/settings";
import { Navigate, Outlet } from "react-router-dom";

function Layout() {
  const { user } = AuthState();
  const [collapsed, setCollapsed] = useState(false);

  if (user && user.role.name === USER_ROLES.ADMIN) {
    return (
      <NavProvider>
        <div className="flex h-screen bg-[#F4F4F4] overflow-hidden">
          {/* Responsive sidebar based on collapse state and screen size */}
          <div className={`absolute top-0 left-0 z-50 flex h-screen w-[300px] flex-col overflow-y-hidden duration-300 lg:static lg:translate-x-0 ${collapsed?'translate-x-0':' -translate-x-full'}`}>

          {/* </div> */}
          {/* <div className={`transform ${collapsed ? 'translate-x-[-100%] md:translate-x-0' : 'translate-x-0'} transition-transform duration-300 ease-in-out`}> */}
            <Menu setCollapsed={setCollapsed} collapsed={collapsed} />
          </div>
          <div className="flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
            <Header setCollapsed={setCollapsed} collapsed={collapsed} />
            <div className="flex-grow overflow-y-auto">
              <Outlet />
            </div>
          </div>
        </div>
      </NavProvider>
    );
  }

  return <Navigate to="/" />;
}

export default Layout;