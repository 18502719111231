// import React, { useEffect, useState } from 'react';
// import { Space, Table, Button, Popconfirm } from 'antd';
// import { getCategoryList, deleteCategory } from '../../../services/categoryService';
// import { HiPencilAlt, HiOutlineTrash } from 'react-icons/hi';
// import { useNavigate } from 'react-router-dom';

// const MealTypesTable = () => {
//     const [loading, setLoading] = useState(false);
//     const [categories, setCategories] = useState([]);
//     const navigate = useNavigate();

//     useEffect(() => {
//         fetchCategories();
//     }, []);

//     const fetchCategories = async () => {
//         setLoading(true);
//         try {
//             const response = await getCategoryList();
//             const { data } = response;
//             setCategories(data.list);
//         } catch (error) {
//             console.error('Error fetching categories:', error);
//         } finally {
//             setLoading(false);
//         }
//     };

//     const handleEdit = (categoryId) => {
//         navigate(`/editCategory/${categoryId}`);
//     };

//     const columns = [
//         {
//             title: 'Name',
//             dataIndex: 'name',
//             key: 'name',
//         },
//         {
//             title: 'Action',
//             key: 'action',
//             render: (_, record) => (
//                 <Space size="middle">
//                     <Button icon={<HiPencilAlt size={20} />} onClick={() => handleEdit(record.id)} className="p-1" />
//                     <Popconfirm 
//                         title="Are you sure to delete this category?" 
//                         onConfirm={() => handleDelete(record.id)} 
//                         okText="Yes" 
//                         cancelText="No" 
//                         okButtonProps={{ danger: true }}
//                     >
//                         <Button icon={<HiOutlineTrash size={20} />} danger className="p-1" />
//                     </Popconfirm>
//                 </Space>
//             ),
//         }
        
//     ];

//     const handleDelete = async (categoryId) => {
//         try {
//             await deleteCategory(categoryId);
//             setCategories(categories.filter(category => category.id !== categoryId));
//         } catch (error) {
//             console.error('Error deleting category:', error);
//         }
//     };

//     return (
//         <div className='overflow-x-auto' style={{ fontFamily: 'Lato' }}>
//             <Table
//                 style={{ fontFamily: 'Lato' }}
//                 dataSource={categories}
//                 columns={columns}
//                 rowKey="id"
//                 loading={loading}
//                 pagination={{ pageSize: 10 }}
//                 scroll={{ x: 800 }} // Ensures horizontal scroll on small screens
//             />
//         </div>
//     );
// }

// export default MealTypesTable;


import React, { useEffect, useState } from "react";
import { Space, Table, Button, Popconfirm, Modal, Form, Input, message } from "antd";
import { getCategoryList, deleteCategory, getCategoryById, updateCategory } from "../../../services/categoryService";
import { HiPencilAlt, HiOutlineTrash } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

const MealTypesTable = () => {
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false); // For modal visibility
  const [selectedCategoryId, setSelectedCategoryId] = useState(null); // For storing selected category ID
  const [form] = Form.useForm(); // For Ant Design Form
  const navigate = useNavigate();

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    setLoading(true);
    try {
      const response = await getCategoryList();
      const { data } = response;
      setCategories(data.list);
    } catch (error) {
      console.error("Error fetching categories:", error);
    } finally {
      setLoading(false);
    }
  };

  // Handle edit button click
  const handleEdit = async (categoryId) => {
    setSelectedCategoryId(categoryId);
    setIsModalVisible(true); // Show modal

    try {
      const { data } = await getCategoryById(categoryId); // Fetch category by ID
      form.setFieldsValue({
        name: data.name,
        tagName: data.tagName,
      });
    } catch (error) {
      console.error("Error fetching category data:", error);
      message.error("Failed to load category data");
    }
  };

  // Handle form submit for updating category
  const handleUpdate = async (values) => {
    try {
      const response = await updateCategory(selectedCategoryId, values); // PATCH request to update category
      if (response?.success) {
        message.success("Category updated successfully");
        fetchCategories(); // Refresh the list after update
        setIsModalVisible(false); // Close modal
      } else {
        message.error("Failed to update category");
      }
    } catch (error) {
      console.error("Error updating category:", error);
      message.error(`Update failed: ${error.message}`);
    }
  };

  const handleDelete = async (categoryId) => {
    try {
      await deleteCategory(categoryId);
      setCategories(categories.filter((category) => category.id !== categoryId));
    } catch (error) {
      console.error("Error deleting category:", error);
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button icon={<HiPencilAlt size={20} />} onClick={() => handleEdit(record.id)} className="p-1" />
          <Popconfirm
            title="Are you sure to delete this category?"
            onConfirm={() => handleDelete(record.id)}
            okText="Yes"
            cancelText="No"
            okButtonProps={{ danger: true }}
          >
            <Button icon={<HiOutlineTrash size={20} />} danger className="p-1" />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div className="overflow-x-auto" style={{ fontFamily: "Lato" }}>
      <Table
        style={{ fontFamily: "Lato" }}
        dataSource={categories}
        columns={columns}
        rowKey="id"
        loading={loading}
        pagination={{ pageSize: 10 }}
        scroll={{ x: 800 }} // Ensures horizontal scroll on small screens
      />

      {/* Edit Category Modal */}
      <Modal
        title="Edit Category"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={() => {
          form.validateFields().then((values) => {
            handleUpdate(values); // Submit updated form data
          });
        }}
        okText="Update"
        okButtonProps={{ danger: true }}
        cancelText="Cancel"
        confirmLoading={loading}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: "Please input the category name!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Tag Name"
            name="tagName"
            rules={[{ required: true, message: "Please input the tag name!" }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default MealTypesTable;
