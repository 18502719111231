import React from 'react';
import { useParams } from 'react-router-dom';
import UserEditForm from './UserEditForm';

const EditMemberPage = () => {
  const { userId } = useParams();

  return (
    <div>
      <h1 className="text-start m-5 px-12 text-3xl font-bold md:block hidden" style={{fontFamily:'Lato'}}>Edit Member</h1>
      <UserEditForm userId={userId} />
    </div>
  );
};

export default EditMemberPage;
