import React, { useState } from 'react'
import { axiosHttp } from '../../../intercepters/axiosHandler';
import { BASE_URL } from '../../../constants/settings';
import { message } from 'antd';

const CreateCategory = () => {

  const [formData, setFormData] = useState({
    name:'',
    tagName:''
  })

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
};

const handleSubmit = async (e) => {
  e.preventDefault();
  try {
      const response = await axiosHttp.post(`${BASE_URL}/api/v1/categories`, formData);
      message.success('category added successfully!');
      console.log("category Creation Response:", response.data);
  } catch (error) {
      console.error('Error creating category:', error);
      message.error('Failed to create category. Please check the console for more details.');
  }
};

  return(
    <div className="max-w-4xl mx-auto p-5" style={{fontFamily:'Lato'}}>
            <h1 className="text-lg font-bold mb-4">Create New Meal</h1>
            <form onSubmit={handleSubmit} className="grid grid-cols-2 gap-4">
                <div className="flex flex-col col-span-2">
                    <label htmlFor="name" className="mb-2">Name:</label>
                    <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required className="p-2 border rounded" />
                </div>
                <div className="flex flex-col col-span-2">
                    <label htmlFor="tagName" className="mb-2">Tag Name:</label>
                    <input type="text" id="tagName" name="tagName" value={formData.tagName} onChange={handleChange} required className="p-2 border rounded" />
                </div>
                <button type="submit" className="col-span-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    Submit
                </button>
            </form>
        </div>
  );
};
export default CreateCategory;