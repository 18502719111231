import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { getMealById, updateMeal, uploadMealImage } from '../../../services/mealImageService';
import { Form, Input, Button, Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const MealImageUpdate = () => {
  const { mealId } = useParams();
  const { register, handleSubmit, setValue, formState: { errors } } = useForm();
  const [meal, setMeal] = useState(null);
  const [error, setError] = useState(null);
  const [imageFile, setImageFile] = useState(null);

  useEffect(() => {
    if (!mealId) {
      setError('Meal ID is required.');
      return;
    }

    // Fetch the meal details on component mount
    const fetchMeal = async () => {
      try {
        const response = await getMealById(mealId);
        const data = response.data;
        setMeal(data);
        
        // Populate form fields with the fetched data
        setValue('name', data.name);
      } catch (error) {
        console.error('Error fetching meal details:', error);
        setError('Failed to fetch meal details. Please try again later.');
      }
    };

    fetchMeal();
  }, [mealId, setValue]);

  const handleImageChange = ({ file }) => {
    setImageFile(file);
  };

  const onSubmit = async (formData) => {
    try {
      // Update meal details
      await updateMeal(mealId, formData);
      message.success('Meal updated successfully.');

      // Upload meal image if an image file is selected
      if (imageFile) {
        const formData = new FormData();
        formData.append('mealFile', imageFile);

        await uploadMealImage(mealId, formData);
        message.success('Meal image uploaded successfully.');
      }
    } catch (error) {
      console.error('Error updating meal or uploading image:', error);
      message.error('Failed to update meal or upload image. Please try again later.');
    }
  };

  if (error) {
    return <div>{error}</div>;
  }

  if (!meal) {
    return <div>Loading...</div>;
  }

  return (
    <Form onFinish={handleSubmit(onSubmit)} layout="vertical">
      <Form.Item
        label="Meal Name"
        validateStatus={errors.name ? 'error' : ''}
        help={errors.name ? errors.name.message : ''}
      >
        <Input
          name='name'
          {...register('name', { required: 'Name is required' })}
        />
      </Form.Item>
      
      <Form.Item label="Meal Image">
        <Upload
          beforeUpload={() => false}  // Prevent automatic upload
          onChange={handleImageChange}
        >
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload>
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">Update Meal</Button>
      </Form.Item>
    </Form>
  );
};

export default MealImageUpdate;
