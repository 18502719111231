// Header.js
import React from 'react';
import { HiMenuAlt2 } from "react-icons/hi";
import { App, Dropdown } from "antd";
import { removeCurrentUser } from "../services/authService";
import { useNavigate } from "react-router-dom";
import Dp from "../images/Dp.jpeg";
//import { NavState } from "../context/navContext";

function Header({ setCollapsed, collapsed }) {
  // globalstate
  //const { setNavEnabled } = NavState();
  //
  const navigate = useNavigate();
  const { message } = App.useApp();

  const items = [
    {
      key: "logout",
      danger: true,
      label: "Logout",
    },
  ];

  const logout = () => {
    removeCurrentUser();
    message.success("User has been logged out!");
    setTimeout(() => {
      navigate("/login");
      window.location.reload();
    }, 1000); // Adding a short delay to ensure the message is displayed before redirect
  };

  const onDropdownClick = ({ key }) => {
    if (key === "logout") {
      logout();
    }
  };

  return (
    <header className="px-3 xl:px-12 py-4 border-b border-black/10 flex items-center justify-between xl:justify-end" style={{fontFamily:'Lato'}}>
      <div className="block xl:hidden" style={{fontFamily:'Lato'}}>
        <HiMenuAlt2
          className="cursor-pointer"
          size={24}
          onClick={() => {
            setCollapsed(!collapsed);
          }}
        />
      </div>
      <Dropdown
        trigger={"click"}
        placement="bottomRight"
        menu={{
          items,
          onClick: onDropdownClick,
        }}
      >
        <img
          className="w-10 h-10 rounded-full cursor-pointer"
          src={Dp}
          alt=""
        />
      </Dropdown>
    </header>
  );
}

export default Header;