import React, { useState, useEffect } from "react";
import { Table, Button, Input, Select, DatePicker, message } from "antd";
import { getMealList } from "../../../services/mealService";
import { getCategoryList } from "../../../services/categoryService";
import { upsertMenu } from "../../../services/menuService";

const { RangePicker } = DatePicker;
const { Search } = Input;

const MenuTable = () => {
  const [mealData, setMealData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [menuDateRange, setMenuDateRange] = useState([]);
  const [selectedMeals, setSelectedMeals] = useState([]);

  useEffect(() => {
    fetchMeals();
    fetchCategories();
  }, []);

  useEffect(() => {
    fetchMeals(
      pagination.current,
      pagination.pageSize,
      searchQuery,
      selectedCategory
    );
  }, [searchQuery, selectedCategory, pagination.current, pagination.pageSize]);

  const fetchMeals = async (
    page = 1,
    pageSize = 10,
    keywords = "",
    categoryIds = null
  ) => {
    setLoading(true);
    setMealData([]); // Clear previous meal data before fetching new data
    try {
      const response = await getMealList({
        page,
        limit: pageSize,
        keywords,
        categoryIds,
      });
      if (response.success) {
        const meals = response.data.list.map((meal) => ({
          ...meal,
          categories: Array.isArray(meal.categories) ? meal.categories : [],
        }));
        setMealData(meals);
        setPagination((prev) => ({
          ...prev,
          total: response.data.count,
          current: page,
          pageSize,
        }));
      } else {
        throw new Error("Failed to fetch meals");
      }
    } catch (error) {
      console.error("Error fetching meals:", error);
      message.error("Failed to fetch meals");
    } finally {
      setLoading(false);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await getCategoryList();
      if (response.success) {
        setCategories(response.data.list);
      } else {
        throw new Error("Failed to fetch categories");
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
      message.error("Failed to fetch categories");
    }
  };

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

  const handleSelectChange = (newSelectedRowKeys, newSelectedRows) => {
    const newSelectedMeals = [...selectedMeals];
    newSelectedRows.forEach((row) => {
      if (!newSelectedMeals.find((meal) => meal.id === row.id)) {
        newSelectedMeals.push(row);
      }
    });
    newSelectedMeals.forEach((meal, index) => {
      if (!newSelectedRowKeys.includes(meal.id)) {
        newSelectedMeals.splice(index, 1);
      }
    });
    setSelectedMeals(newSelectedMeals);
    setSelectedRowKeys(newSelectedMeals.map((meal) => meal.id));
  };

  const handleDateChange = (dates) => {
    setMenuDateRange(dates);
  };

  const disabledDate = (current) => {
    return current && current < new Date().setHours(0, 0, 0, 0);
  };

  const submitMenu = async () => {
    if (!menuDateRange.length || !selectedMeals.length) {
      message.error("Please select a date range and at least one meal.");
      return;
    }

    const payload = {
      startDate: menuDateRange[0].format("YYYY-MM-DD"),
      endDate: menuDateRange[1].format("YYYY-MM-DD"),
      mealIds: selectedMeals.map((meal) => meal.id),
    };

    try {
      const response = await upsertMenu(payload);

      if (response && response.data && response.data.success) {
        message.success("Menu has been submitted!");
      } else {
        throw new Error("Failed to submit menu");
      }
    } catch (error) {
      console.error("Error submitting menu:", error);
      message.error("Failed to submit menu");
    }
  };

  const handleSearch = (value) => {
    setSearchQuery(value);
  };

  const handleCategoryChange = (value) => {
    setSelectedCategory(value);
  };

  const columns = [
    // { title: "Meal ID", dataIndex: "id", key: "id", responsive: ["md"] },
    { title: "Meal Name", dataIndex: "name", key: "name" },
    {
      title: "Type",
      dataIndex: "categories",
      key: "type",
      render: (categories) =>
        Array.isArray(categories)
          ? categories.map((cat) => cat.tagName).join(", ")
          : "",
      responsive: ["lg"],
    },
  ];

  return (
    <div className="flex flex-col md:flex-row" style={{ fontFamily: "Lato" }}>
      <div className="flex-grow md:w-2/3 p-2">
        <div className="flex mb-4">
          <Search
            placeholder="Search meals"
            style={{ marginRight: 16 }}
            className="w-full"
            onSearch={handleSearch}
            enterButton
          />
          <Select
            placeholder="Filter by category"
            style={{ width: 200 }}
            allowClear
            onChange={handleCategoryChange}
          >
            {categories.map((category) => (
              <Select.Option key={category.id} value={category.id}>
                {category.name}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className="overflow-x-auto">
          <Table
            rowSelection={{
              selectedRowKeys,
              onChange: handleSelectChange,
              preserveSelectedRowKeys: true,
            }}
            columns={columns}
            dataSource={mealData}
            loading={loading}
            rowKey="id"
            pagination={pagination}
            onChange={handleTableChange}
            scroll={{ x: 400 }}
          />
        </div>
      </div>
      <div className="w-full md:w-1/3 px-4 py-2">
        <RangePicker
          className="w-full"
          format="YYYY-MM-DD"
          onChange={handleDateChange}
          value={menuDateRange}
          //disabledDate={disabledDate}
          style={{ marginBottom: 16 }}
        />
        <div className="overflow-x-auto">
          <Table
            columns={[
              {
                title: `${selectedMeals.length} Meals`,
                dataIndex: "name",
                key: "name",
              },
            ]}
            dataSource={selectedMeals}
            rowKey="id"
            pagination={false}
            scroll={{ y: 240 }}
          />
        </div>
        <Button
          className="bg-black text-white w-full rounded-md mt-4"
          type="primary"
          onClick={submitMenu}
          disabled={!menuDateRange.length || !selectedMeals.length}
        >
          Confirm Menu
        </Button>
      </div>
    </div>
  );
};

export default MenuTable;