// import "./App.css";
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import ScrollTop from "./components/ScrollTop";
// import Login from "./pages/Login";
// import Dashboad from "./pages/Dashboad";
// import AuthProvider from "./context/authContext";
// import PrivateRoute from "./utils/privateRoute";

// function App() {
//   return (
//     <div className="App">
//       <AuthProvider>
//         <Router>
//           <ScrollTop />
//           <Routes>
//             <Route path="/login" element={<Login />} />

//             {/* Admin only - Protected Routes */}
//             <Route element={<PrivateRoute />}>
// //               <Route path="/" element={<Dashboad />} />
// //             </Route>
//           </Routes>
//         </Router>
//       </AuthProvider>
//     </div>
//   );
// }

// export default App;

import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Layout from "./layouts/layout";
import AuthProvider from "./context/authContext";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollTop from "./components/ScrollTop";
import Login from "./pages/Login";
import PrivateRoute from "./utils/privateRoute";
import { App as AntdApp } from "antd";
import MealTypes from "./pages/MealTypes";
import Meals from "./pages/Meals";
import WeeklyMenu from "./pages/WeeklyMenu";
import Members from "./pages/Members";
import NotFound from "./components/NotFound/NotFound";
import Orders from "./pages/Orders";
import { ChakraProvider } from '@chakra-ui/react'
import EditMeal from "./components/dashboard/Meals/editMeals";
import MealEditPage from "./components/dashboard/Meals/MealEditPage";
import MemberEdit from "./components/dashboard/Members/MemberEdit";
import EditCategory from "./components/dashboard/MealTypes/EditCategory";
import CreateMeal from "./components/dashboard/Meals/CreateMeal";
import CreateCategory from "./components/dashboard/MealTypes/CreateCategory";
import MealImageUpdate from "./components/dashboard/Meals/MealImageUpdate";

function App() {
  const router = createBrowserRouter([
    {
      path: "/", // Define the root path
      element: <Login />, // Render the Login component for the root path
    },
    {
      path: "/login",
      element: <Login/>,
    },
    {

      element: <Layout />,
      children: [
        {
          path: "/orders",
          index: true,
          element: <Orders/>,
        },
        {
          path: "/members",
          element: <Members />,
        },
        {
          path: "/weekly menu",
          element: <WeeklyMenu/>,
        },
        {
          path: "/meals",
          element: <Meals/>,
        },
        {
          path: "/mealImage/:mealId",
          element: <MealImageUpdate/>,
        },
        {
          path: "/create-meal",
          element: <CreateMeal/>,
        },
        {
          path: "/meal types",
          element: <MealTypes/>,
        },
        {
          path: "/editMeal/:mealId",
          element: <MealEditPage />,
        },
        {
          path: "/editMember/:userId",
          element: <MemberEdit/>
        },
        {
          path: "/editCategory/:categoryId",
          element: <EditCategory/>
        },
        {
          path: "/createCategory",
          element: <CreateCategory/>
        }
      ]
    },
    {
      path: "/*",
      element: <NotFound/>,
    },
  ]);

  return (
    <ChakraProvider>
      <AntdApp>
        <AuthProvider>
          <RouterProvider router={router} />
        </AuthProvider>
      </AntdApp>
    </ChakraProvider>
  );
}

export default App;