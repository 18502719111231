import React, { useEffect, useState } from 'react';
import { Button, Space, Table, Tag, message } from 'antd';
import { HiPencilAlt } from 'react-icons/hi';
import { MdDeleteForever } from "react-icons/md";
import { getMealList, deleteMeal } from '../../../services/mealService';
import { useNavigate } from 'react-router-dom';
import { Input } from 'antd';
const { Search } = Input;

const MealsTable = () => {
  const [loading, setLoading] = useState(false);
  const [meals, setMeals] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [keywords, setKeywords] = useState('');
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const navigate = useNavigate();

  const handleEdit = (mealId) => {
    navigate(`/editMeal/${mealId}`);
  };

  const handleDelete = async (mealId) => {

    const isConfirmed = window.confirm("Are you sure you want to delete this meal?");
    if (isConfirmed){
      try{
        setLoading(true);
        await deleteMeal(mealId);
        message.success("Meal Deleted !");
        fetchMeals();
      }catch(error){
        message.error('Error deleting meal');
        console.error('Error deleting meal:', error);
      }finally{
        setLoading(false);
      }
    }

  };

  const fetchMeals = async () => {
    setLoading(true);
    try {
      const { pagination } = tableParams;
      const payload = { page: pagination.current, limit: pagination.pageSize, keywords };
      const response = await getMealList(payload);
      const { data } = response;
      const mealData = data.list.map(meal => ({
        id: meal.id,
        name: meal.name,
        categories: meal.categories,
      }));
      setMeals(mealData);
      setTotalCount(data.count);
    } catch (error) {
      console.error('Error fetching meals:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMeals();
  }, [tableParams.pagination.current, tableParams.pagination.pageSize, keywords]);

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      sorter,
    });
  };

  const handleSearch = (value) => {
    setKeywords(value);
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        current: 1,
      },
    });
  };

  const columns = [
    // {
    //   title: 'Meal ID',
    //   dataIndex: 'id',
    // },
    {
      title: 'Meal Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Type',
      key: 'categories',
      dataIndex: 'categories',
      render: (_, { categories }) => (
        <>
          {categories.map((category) => {
            let color;
            switch (category.name) {
              case 'Healthy':
                color = '#90D26D';
                break;
              case "Chef's Choice":
                color = '#FF9800';
                break;
              case 'Keto':
                color = '#D20062';
                break;
              case 'Protein Plus':
                color = '#0C359E';
                break;
              default:
                color = category.name.length > 5 ? 'geekblue' : 'green';
                break;
            }
            return (
              <Tag color={color} key={category.name}>
                {category.name.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button icon={<HiPencilAlt size={20} />} onClick={() => handleEdit(record.id)} className="p-1"/>
          <Button icon={<MdDeleteForever size={20} />} onClick={() => handleDelete(record.id)} className="p-1"/>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Search
        placeholder="Search meals"
        enterButton="Search"
        size="large"
        onSearch={handleSearch}
        style={{ marginBottom: '20px' }}
      />
      <Table
        style={{ fontFamily: 'Lato' }}
        dataSource={meals}
        columns={columns}
        rowKey="id"
        loading={loading}
        pagination={{
          current: tableParams.pagination.current,
          pageSize: tableParams.pagination.pageSize,
          total: totalCount,
          responsive: true,
        }}
        scroll={{ x: 800 }}
        onChange={handleTableChange}
      />
    </>
  );
};

export default MealsTable;