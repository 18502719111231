import React, { useEffect, useState } from 'react';
import { Table, message, Button } from 'antd';
import { getOrderList, updateOrderToPending, updateOrderToShipped, updateOrderToInTransit, updateOrderToDelivered, updateOrderToPaid } from '../../../services/orderService';

const BasicTable = () => {
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  useEffect(() => {
    fetchOrders();
  }, [JSON.stringify(tableParams)]);

  const fetchOrders = async () => {
    setLoading(true);
    try {
      const payload = { page: tableParams.pagination.current, limit: tableParams.pagination.pageSize };
      const response = await getOrderList(payload);
      const { data } = response;
      const ordersData = data.list.map(order => ({
        id: order.id,
        categories: order.categories,
        planAmount: order.planAmount,
        shippingAmount: order.shippingAmount,
        totalAmount: order.totalAmount,
        status: order.status,
      }));
      setTotalCount(data.count);
      setOrders(ordersData);
    } catch (error) {
      console.error('Error fetching orders:', error);
      message.error('Failed to fetch orders data');
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  const handleUpdateOrderStatus = async (orderId, updateFunction, successMessage, warningMessage) => {
    try {
      const order = orders.find(order => order.id === orderId);
      if (order) {
        await updateFunction(orderId);
        fetchOrders();
        message.success(successMessage);
      } else if (warningMessage) {
        message.warning(warningMessage);
      }
    } catch (error) {
      console.error(`Error updating order status:`, error);
      message.error(`Failed to update order status`);
    }
  };

  const columns = [
    {
      title: 'Order ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Order Category',
      dataIndex: ['categories', 0, 'name'],
      key: 'name',
    },
    {
      title: 'Plan Amount',
      dataIndex: 'planAmount',
      key: 'planAmount',
      responsive: ['md'],
    },
    {
      title: 'Shipping Amount',
      dataIndex: 'shippingAmount',
      key: 'shippingAmount',
      responsive: ['md'],
    },
    {
      title: 'Total Amount',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      responsive: ['md'],
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => {
        let color = '#AD0000'; // Default color
        let backgroundColor = '#FFE1E1';

        if (record.status === 'PLACED') {
          color = '#FF6B00'; // Color for PLACED status
          backgroundColor = '#FFECBA';
        } else if (record.status === 'PAID') {
          color = '#69B42E'; // Color for PAID status
          backgroundColor = '#DDFFC2';
        } else if (record.status === 'PENDING') {
          color = '#FFD700'; // Color for PENDING status
          backgroundColor = '#FFFFCC';
        } else if (record.status === 'SHIPPED') {
          color = '#69B42E'; // Color for SHIPPED status
          backgroundColor = '#DDFFC2';
        } else if (record.status === 'INTRANSIT') {
          color = '#0052CC'; // Color for INTRANSIT status
          backgroundColor = '#B3D4FF';
        }

        return <div className='font-bold rounded-lg text-center cursor-pointer text-sm px-2' style={{ color, backgroundColor }}>{text}</div>;
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <div className='flex justify-center space-x-2'>
          {record.status === 'PAID' && (
            <Button
              size="small"
              onClick={() => handleUpdateOrderStatus(record.id, updateOrderToShipped, 'Order marked as SHIPPED')}
            >
              Prepare for Shipment
            </Button>
          )}
          {record.status === 'SHIPPED' && (
            <Button
              size="small"
              onClick={() => handleUpdateOrderStatus(record.id, updateOrderToInTransit, 'Order marked as INTRANSIT')}
            >
              Mark as In Transit
            </Button>
          )}
          {record.status === 'INTRANSIT' && (
            <Button
              size="small"
              onClick={() => handleUpdateOrderStatus(record.id, updateOrderToDelivered, 'Order marked as DELIVERED')}
            >
              Deliver
            </Button>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className='overflow-x-auto'>
      <Table
        style={{ fontFamily: 'Lato', textAlign: 'center' }}
        dataSource={orders}
        columns={columns}
        rowKey="id"
        loading={loading}
        pagination={{
          current: tableParams.pagination.current,
          pageSize: tableParams.pagination.pageSize,
          total: totalCount,
        }}
        onChange={handleTableChange}
        scroll={{ x: 800 }} // Ensures horizontal scroll on small screens
      />
    </div>
  );
};

export default BasicTable;
