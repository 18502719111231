import React from 'react';
import MemberTable from '../components/dashboard/Members/MemberTable';

const Members = () => {
    return (
        <div style={{fontFamily:'Lato'}}>
            <h1 className="text-start m-5 px-12 text-3xl font-bold md:block hidden">Members</h1>
            <div className="mx-auto px-12">
                <MemberTable />
            </div>
        </div>
    );
}

export default Members;
