import React, { useState } from 'react';
import { Button, Form, Input, InputNumber, message } from 'antd';
import { updateMeal } from '../../../services/mealService'; // Ensure this is the correct import path

const { TextArea } = Input;

const MealEditForm = ({ mealId }) => {
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    price: 0,
    posionSize: 0, // Check if 'posionSize' is a typo for 'portionSize'
    ingredients: '',
    contains: '',
    carbohydrate: 0,
    protein: 0,
    fat: 0,
    calories: 0,
    foodAllergyNotice: '',
    storageInstructions: '',
    heatingFromFreshInstructions: '',
    heatingFromFrozenInstructions: '',
    categoryIds: [],
    mealNutritions: []
  });

  const handleChange = (name, value) => {
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    try {
      const response = await updateMeal(mealId, formData);
      console.log('Update successful:', response);
      message.success('Meal updated successfully!');
    } catch (error) {
      console.error('Failed to update meal:', error);
      message.error('Failed to update meal.');
    }
  };

  return (
    <Form
      layout="vertical"
      onFinish={handleSubmit}
    >
      <Form.Item label="Meal Name">
        <Input value={formData.name} onChange={e => handleChange('name', e.target.value)} />
      </Form.Item>
      <Form.Item label="Description">
        <TextArea rows={4} value={formData.description} onChange={e => handleChange('description', e.target.value)} />
      </Form.Item>
      <Form.Item label="Price">
        <InputNumber min={0} value={formData.price} onChange={value => handleChange('price', value)} />
      </Form.Item>
      <Form.Item label="Portion Size">
        <InputNumber min={0} value={formData.posionSize} onChange={value => handleChange('posionSize', value)} />
      </Form.Item>
      <Form.Item label="Ingredients">
        <TextArea rows={4} value={formData.ingredients} onChange={e => handleChange('ingredients', e.target.value)} />
      </Form.Item>
      <Form.Item label="Contains">
        <Input value={formData.contains} onChange={e => handleChange('contains', e.target.value)} />
      </Form.Item>
      <Form.Item label="Carbohydrate (g)">
        <InputNumber min={0} value={formData.carbohydrate} onChange={value => handleChange('carbohydrate', value)} />
      </Form.Item>
      <Form.Item label="Protein (g)">
        <InputNumber min={0} value={formData.protein} onChange={value => handleChange('protein', value)} />
      </Form.Item>
      <Form.Item label="Fat (g)">
        <InputNumber min={0} value={formData.fat} onChange={value => handleChange('fat', value)} />
      </Form.Item>
      <Form.Item label="Calories (kcal)">
        <InputNumber min={0} value={formData.calories} onChange={value => handleChange('calories', value)} />
      </Form.Item>
      <Form.Item label="Food Allergy Notice">
        <TextArea rows={4} value={formData.foodAllergyNotice} onChange={e => handleChange('foodAllergyNotice', e.target.value)} />
      </Form.Item>
      <Form.Item label="Storage Instructions">
        <TextArea rows={4} value={formData.storageInstructions} onChange={e => handleChange('storageInstructions', e.target.value)} />
      </Form.Item>
      <Form.Item label="Heating from Fresh Instructions">
        <TextArea rows={4} value={formData.heatingFromFreshInstructions} onChange={e => handleChange('heatingFromFreshInstructions', e.target.value)} />
      </Form.Item>
      <Form.Item label="Heating from Frozen Instructions">
        <TextArea rows={4} value={formData.heatingFromFrozenInstructions} onChange={e => handleChange('heatingFromFrozenInstructions', e.target.value)} />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">Update Meal</Button>
      </Form.Item>
    </Form>
  );
};

export default MealEditForm;
