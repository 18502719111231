import { BASE_URL } from "../constants/settings";
import { axiosHttp } from "../intercepters/axiosHandler";
import axios from 'axios';

export const getMealList = async ({ page, limit, keywords, categoryIds }) => {
  const params = { page, limit, keywords, categoryIds };
  try {
    const response = await axios.get('https://backend.mealprep.sultanahmet.ca/api/v1/meals', { params });
    return response.data;
  } catch (error) {
    throw error;
  }
};

//create meal 
export const createMeal = async (mealData) => {
    try {
        const response = await axiosHttp.post(`${BASE_URL}/api/v1/meals`, mealData);
        return response.data;
    } catch (error) {
        console.error("Error creating meal:", error);
        throw error;
    }
};


// //Get, create, update, and delete meals along with image management
export const getMealById = async (mealId) => {
    try {
        const response = await axiosHttp.get(`${BASE_URL}/api/v1/meals/${mealId}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching meal by ID:", error);
        throw error;
    }
};

export const updateMeal = async (mealId, mealData) => {
    try {
        const response = await axiosHttp.patch(`${BASE_URL}/api/v1/meals/${mealId}`, mealData);
        console.log('Meal deleted successfully:', response.data);
        return response.data;
    } catch (error) {
        console.error("Error updating meal:", error);
        throw error;
    }
};

//Delete meal
export const deleteMeal = async (mealId) => {
  try {
    const response = await axiosHttp.delete(`${BASE_URL}/api/v1/meals/${mealId}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting meal:", error);
    throw error;
  }
}
  
  export const getMealImage = async (mealId) => {
    try {
      const response = await axios.get(`${BASE_URL}/api/v1/meals/${mealId}/images`);
      return response.data;
    } catch (error) {
      console.error("Error fetching meal image:", error);
      throw error;
    }
  };

export const uploadMealImage = async (mealId, formData) => {
  const response = await axiosHttp.post(`${BASE_URL}/api/v1/meals/${mealId}/images`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return response.data;
};

export const getCategoryList = async () => {
  return axiosHttp.get(`${BASE_URL}/categories`);
};