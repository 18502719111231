import React from 'react';
import MenuTable from '../components/dashboard/WeeklyMenu/MenuTable';

const WeeklyMenu = () => {
    return (
        <div>
            <h1 className="text-start m-5 px-12 text-3xl font-bold" style={{fontFamily:'Lato'}}>Weekly Menu</h1>
            <div className='mx-auto px-12'>
                <MenuTable />
            </div>
        </div>
    );
}

export default WeeklyMenu;
