import React from 'react';
import { Link } from 'react-router-dom';
import Meals from '../components/dashboard/Meals/Meals';
import { PlusOutlined } from '@ant-design/icons';
import MealTypesTable from '../components/dashboard/MealTypes/MealTypesTable';

const MealTypes = () => {
    return (
        <div style={{fontFamily:'Lato'}}>
            <div className='flex justify-between items-center mt-5'>
                <h1 className="text-xl sm:text-3xl font-bold xl:px-12 xl:ms-0 ms-auto">Meal Types</h1>
                <div className='ml-auto px-12'><Link to="/createCategory" className='bg-black text-white py-2 px-4 rounded-md inline-block'><PlusOutlined /> Add Category</Link> {/* Link to navigate to the Create Meal page */}</div>
            </div>
            <div className='mt-5 mx-auto px-12'>
            <MealTypesTable/>
            </div>
        </div>
    );
}

export default MealTypes;