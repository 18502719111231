// import React, { useEffect, useState } from "react";
// import { message } from "antd";
// import {
//   getCategoryById,
//   updateCategory,
// } from "../../../services/categoryService";

// const EditCategory = ({ categoryId }) => {
//   const [categoryData, setCategoryData] = useState({});

//   useEffect(() => {
//     const fetchCategoryData = async () => {
//       try {
//         // Add a check to ensure categoryId exists before making the API call
//         if (categoryId) {
//           const data = await getCategoryById(categoryId);
//           setCategoryData(data);
//         }
//       } catch (error) {
//         console.error("Error fetching category data:", error);
//         message.error("Failed to load category data");
//       }
//     };

//     fetchCategoryData();
//   }, [categoryId]);

//   const handleFormSubmit = async (event) => {
//     event.preventDefault();
//     const formData = {
//       name: event.target.name.value,
//       tagName: event.target.tagName.value,
//     };

//     try {
//       if (categoryId) {
//         const updateResponse = await updateCategory(categoryId, formData);
//         if (updateResponse) {
//           message.success("Category updated successfully");
//         }
//       }
//     } catch (error) {
//       console.error("Error updating category:", error);
//       message.error(`Update failed: ${error.message}`);
//     }
//   };

//   return (
//     <div className="max-w-4xl mx-auto p-5" style={{ fontFamily: "Lato" }}>
//       <form onSubmit={handleFormSubmit} className="grid grid-cols-2 gap-4">
//         <div className="flex flex-col col-span-2">
//           <label htmlFor="name" className="mb-2">
//             Name:
//           </label>
//           <input
//             type="text"
//             id="name"
//             name="name"
//             defaultValue={categoryData.name}
//             className="p-2 border rounded"
//           />
//         </div>
//         <div className="flex flex-col col-span-2">
//           <label htmlFor="tagName" className="mb-2">
//             Tag Name:
//           </label>
//           <input
//             type="text"
//             id="tagName"
//             name="tagName"
//             defaultValue={categoryData.tagName}
//             className="p-2 border rounded"
//           />
//         </div>
//         <button
//           type="submit"
//           className="col-span-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
//         >
//           Update
//         </button>
//       </form>
//     </div>
//   );
// };

// export default EditCategory;


import React, { useEffect, useState } from "react";
import { message } from "antd";
import { useForm } from "react-hook-form"; // Importing from react-hook-form
import { getCategoryById, updateCategory } from "../../../services/categoryService";

const EditCategory = ({ categoryId }) => {
  const { register, handleSubmit, setValue, watch } = useForm(); // Initialize react-hook-form
  const [loading, setLoading] = useState(false);

  // Fetch category data when component mounts or categoryId changes
  useEffect(() => {
    const fetchCategoryData = async () => {
      try {
        if (categoryId) {
          setLoading(true);
          const { data } = await getCategoryById(categoryId);
          setValue("name", data.name || "");
          setValue("tagName", data.tagName || "");
          console.log("Fetched category data: ", data); // Debugging the fetched data
        }
      } catch (error) {
        console.error("Error fetching category data:", error);
        message.error("Failed to load category data");
      } finally {
        setLoading(false);
      }
    };

    fetchCategoryData();
  }, [categoryId, setValue]);

  // Log form data as user types
  const formData = watch();
  useEffect(() => {
    console.log("Form data (as you type): ", formData);
  }, [formData]);

  // Handle form submission to update the category
  const onSubmit = async (data) => {
    console.log("Form submitted with data: ", data); // Debugging

    if (!data.name || !data.tagName) {
      message.error("Both name and tag name are required.");
      return;
    }

    try {
      if (categoryId) {
        setLoading(true);
        const updateResponse = await updateCategory(categoryId, data); // Call API function
        if (updateResponse?.success) {
          message.success("Category updated successfully");
          console.log("Update response: ", updateResponse); // Debugging
        } else {
          message.error("Failed to update category.");
        }
      }
    } catch (error) {
      console.error("Error updating category:", error);
      message.error(`Update failed: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-5" style={{ fontFamily: "Lato" }}>
      <form onSubmit={handleSubmit(onSubmit)} className="grid grid-cols-2 gap-4">
        {/* Name input */}
        <div className="flex flex-col col-span-2">
          <label htmlFor="name" className="mb-2">Name:</label>
          <input
            type="text"
            id="name"
            {...register("name", { required: true })}
            className="p-2 border rounded"
          />
        </div>

        {/* Tag Name input */}
        <div className="flex flex-col col-span-2">
          <label htmlFor="tagName" className="mb-2">Tag Name:</label>
          <input
            type="text"
            id="tagName"
            {...register("tagName", { required: true })}
            className="p-2 border rounded"
          />
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          disabled={loading}
          className="col-span-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          {loading ? "Updating..." : "Update"}
        </button>
      </form>
    </div>
  );
};

export default EditCategory;
