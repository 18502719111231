import React, { useEffect, useState } from 'react';
import MealEditForm from './MealEditForm';
import { getMealById } from '../../../services/mealService';
import { useParams } from 'react-router-dom';

const EditMealPage = () => {
  const [meal, setMeal] = useState(null);
  const [loading, setLoading] = useState(true);
  const { mealId } = useParams();

  useEffect(() => {
    const fetchMeal = async () => {
      try {
        const fetchedMeal = await getMealById(mealId);
        setMeal(fetchedMeal.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching meal:", error);
      }
    };

    fetchMeal();
  }, [mealId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <h1 className='text-3xl font-bold m-5' style={{ fontFamily: 'Lato' }}>Edit Meals</h1>
      <div className='mx-auto px-12'>
        {meal ? (
          <MealEditForm meal={meal} />
        ) : (
          <div>Meal not found</div>
        )}
      </div>
    </>
  );
};

export default EditMealPage;